.button-wrap{
    height: 45px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
    border-radius: 2px;
    display: inline-flex;
    .button-component{
        cursor: pointer;
        padding: 0 20px;
        display: flex;
        align-items: center;
        height: 100%;
        font-size: 14px;
        line-height: 25px;
        text-transform: uppercase;
        letter-spacing: 1px;
        text-align: center;
        font-weight: 600;
        transition: .2s ease;
        border-radius: 2px;
        &.header-button{
            color: var(--primary-color);
            background: var(--white);
            &:hover{
                color: var(--white);
                background: var(--primary-color);
            }
        }
        &.contact-button{
            color: var(--white);
            background: var(--primary-color);
        }
        &.button--disabled{
            transition: none !important;
            pointer-events: none;
            opacity: .6;
        }
    }
}
.hover-link{
    transition: .1s ease;
    &:hover{
        opacity: .8;
    }
}
