/* You can add global styles to this file, and also import other style files */
@use "./assets/shared/scss/reset.scss";
@use "./assets/shared/scss/variables.scss";
@use 'ngx-toastr/toastr';
@use "./assets/shared/scss/fonts.scss";
@use "./assets/shared/scss/buttons.scss";
@use "./assets/shared/scss/icons.scss";
@use "./assets/shared/scss/content.scss";
@use "./assets/shared/scss/overwrites.scss";
@use "./assets/shared/scss/fields.scss";
@use "./assets/shared/scss/mobile.scss";

body{
    margin: 0 !important;
    font-family: 'Avenir Next', sans-serif;

    font-size: 15px;
    color: var(--black);
    background-color: var(--white);
}
.page-container{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}
.grecaptcha-badge { 
    opacity: 0;
    pointer-events: none;
    transition: right 0.3s ease 0s, opacity .2s ease 0s !important;
}
.show-captcha .grecaptcha-badge { 
    opacity: 1;
    pointer-events: all;
}
.no-wrap{
    white-space: nowrap;
}
b.strong{
    font-weight: 600;
}

// .footer-terms{
//     background-color: #006990;
//     color: var(--white);
//     line-height: 25px;
//     font-size: 15px;
//     text-align: center;
//     padding: 20px 0;
// }

.container{
    width: 100%;
    max-width: 1080px;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
}
body.mobile-menu-open{
    overflow: hidden
}
.mt-0{
    margin-top: 0 !important;
}
.mt-120{
    margin-top: 120px !important;
}
.service-details-wrap .page{
    gap: 50px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    img{
        width: 100%;
        border-radius: 2px;
    }
}

// toasts
#toast-container{
    .ngx-toastr{
        box-shadow: 0 3px 12px 0 rgba(144, 144, 144, 0.2) !important;
        padding-top: 16px;
        padding-bottom: 16px;
        padding-left: 53px;
        padding-right: 30px;
        border-radius: 4px;
        background: #fff;
        margin: 0 0 11px;
        width: 400px;
        .toast-close-button{
            background-image: url(/shared-assets/img/icons/x.svg);
            background-repeat: no-repeat;
            background-size: contain;
            width: 10px;
            height: 10px;
            transition: .1s ease;
            position: absolute;
            top: 50%;
            right: 13px;
            transform: translatey(-50%);
            &:hover{
                opacity: .7;
            }
            span{
                display: none;
            }
        }
    }
    .toast-success{
        border-left: 5px solid #5fbb97;
    }
    .toast-warning{
        border-left: 5px solid #ef8a17;
    }
    .toast-error{
        border-left: 5px solid #ff0000;
    }
    .toast-info{
        border-left: 5px solid #ffff00;
    }
    .toast-bottom-right {
        right: 16px;
        bottom: 0px;
    }
    .toast-title{
        font-size: 16px;
        color: var(--primary-color);
    }
    .toast-message{
        font-size: 13px;
        color: #555555;
        margin-top: 5px;
    }
    .toast-warning{
        background-image: url(/shared-assets/img/icons/icon-toast-warning.svg);
        background-repeat: no-repeat;
        background-size: 23px 23px;
        background-position: center left 15px;
    }
    .toast-error{
        background-image: url(/shared-assets/img/icons/icon-toast-error.svg);
        background-repeat: no-repeat;
        background-size: 23px 23px;
        background-position: center left 15px;
    }
    .toast-info{
        background-image: url(/shared-assets/img/icons/icon-toast-info.svg);
        background-repeat: no-repeat;
        background-size: 23px 23px;
        background-position: center left 15px;
    }
    .toast-success{
        background-image: url(/shared-assets/img/icons/icon-toast-success.svg);
        background-repeat: no-repeat;
        background-size: 21px 21px;
        background-position: center left 15px;
    }
    
}


