
.content{
    p{
        margin-bottom: 22px;
        &:last-of-type{
            margin-bottom: 0;
        }
        strong{
            font-weight: 600;
        }
    }
}