
.icon{
    flex-shrink: 0;
    display: inline-block;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    &.icon-mobile-menu{
        background-image: url(/assets/shared/img/icons/mobile-menu.svg);
        width: 25px;
        height: 25px;
    }
    &.icon-close-mobile-menu{
        background-image: url(/assets/shared/img/icons/close-mobile-menu.svg);
        width: 25px;
        height: 25px;
    }
    &.icon-phone{
        background-image: url(/assets/shared/img/icons/phone.svg);
        width: 20px;
        height: 20px;
    }
    &.icon-mail{
        background-image: url(/assets/shared/img/icons/mail.svg);
        width: 20px;
        height: 20px;
    }
    &.icon-search{
        background-image: url(/assets/shared/img/icons/search.svg);
        width: 25px;
        height: 25px;
    }
}