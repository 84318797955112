// nav
body {
    .mobile-menu-spacer{
        height: 54px;
    }
    .navbar-wrap--mobile{
        .redirections-navbar{
            transition: height .2s ease;
            position: fixed;
            z-index: 999;
            top: 54px;
            background-color: var(--primary-color);
            left: 0;
            right: 0;
            bottom: 0;
            height: 0px;
            overflow-y: scroll;
            &::after{
                content: '';
                width: 100%;
                height: 1px;
                background-color: var(--primary-color-border);
                position: fixed;
                top: 54px;
                left: 0;
                display: block;
                pointer-events: none;
                transition: .1s ease;
                opacity: 0;
            }
            &.active{
                opacity: 1;
                height: calc(100vh - 54px);
                &::after{
                    opacity: 1;
                }
            }
            ul{
                flex-direction: column;
                width: 100%;
                li{
                    margin: 0;
                    a{
                        padding: 20px;
                        width: 100%;
                        text-decoration: none !important;
                        border-bottom: 1px solid var(--primary-color-border);
                        &:hover, &.active{
                            background-color: var(--primary-color-hover);
                        }
                    }
                }
            }
        }
    }
    .navbar-wrap--mobile-menu-open{
        position: fixed;
        z-index: 999;
        top: 0;
        left: 0;
        right: 0;
    }
}

@media only screen and (max-width: 900px) {

    // header
    .header-bg-image{
        height: auto !important;
        min-height: 400px !important;
        .header-title{
            font-size: 42px !important;
            line-height: 60px !important;
        }
        .header-text{
            font-size: 17px !important;
            line-height: 25px !important;
            max-width: 500px !important;
        }
        .header-contact{
            flex-direction: column;
            margin-top: 32px !important;
            align-items: flex-start;
            .button-wrap{
                margin-bottom: 10px !important;
            }
        }
        &.home-header{
            .container{
                margin-bottom: 0 !important;
                .header-text{
                    font-size: 14px !important;
                    letter-spacing: 6px !important;
                    line-height: 25px !important;
                }
            }
        }
        &.contact-header{
            .header-contact{
                margin-top: 26px !important;
                .telephone-link{
                    font-size: 28px !important;
                    line-height: 32px !important;
                }
            }
        }
    }

    // home
    .home-wrap{
        .information-wrap{
            flex-direction: column;
            gap: 0 !important;
            .left{
                width: 100% !important;
                order: 2;
                display: flex;
                flex-direction: column;
                .what-to-do-wrap{
                    order: 2;
                    padding: 40px 30px !important;
                    margin-top: 0 !important;
                }
                .we-are-there-wrap{
                    order: 1;
                    margin-top: 40px !important;
                    margin-bottom: 10px !important;
                }
            }
            .right{
                width: 100% !important;
                order: 1;
                margin-top: -40px !important;
                padding: 30px 20px !important;
            }
        }
        .services-wrap{
            font-size: 25px !important;
            line-height: 35px !important;
            margin-top: 80px !important;
            margin-bottom: 30px !important;
        }
        margin-bottom: 80px !important;
    }
    
    // general
    .more-link{
        font-size: 13px !important;
        line-height: 25px !important;
    }

    // deceased
    .deceased-wrap{
        grid-template-columns: 1fr 1fr !important;
        .full-name{
            font-size: 18px !important;
            line-height: 35px !important;
        }
    }

}
@media only screen and (max-width: 700px) {

    // general
    .page-title{
        font-size: 24px !important;
        line-height: 32px !important;
        margin-bottom: 5px !important;
    }

    // home cards
    .home-wrap{
        .card-wrap{
            grid-template-columns: 1fr !important;
        }
    }
    // footer
    .footer-establishments{
        grid-template-columns: 1fr !important;
    }
    .footer-title{
        margin-bottom: 30px !important;
    }

    // services
    .services-wrap{
        margin-bottom: 50px !important;
    }
    .service-wrap{
        .services-links{
            grid-template-columns: 1fr !important;
            &>a{
                font-size: 15px !important;
                line-height: 20px !important;
                margin-bottom: 10px;
            }
        }
    }
    .service-details-wrap{
        margin-bottom: 50px !important;
        .full-services-wrap{
            grid-template-columns: 1fr !important;
            .service-title{
                font-size: 20px !important;
                line-height: 30px !important;
            }
            a{
                font-size: 15px !important;
                line-height: 20px !important;
                margin-bottom: 10px;
                display: inline-block;
            }
        }
        
    }

    .service-details-wrap .page{
        grid-template-columns: 1fr;
    }

    .history-wrap{
        .date{
            font-size: 18px !important;
            line-height: 25px !important;
            margin-top: 36px !important;
            margin-bottom: 10px !important;
        }
        margin-bottom: 80px !important;
    }

    // user cards
    .about-us-wrap{
        img{

        }
        .about-user-info{
            padding: 20px !important;
            .about-user-icon{
                i{
                    margin-right: 10px !important;
                }
                a{
                    word-break: break-word;
                }
            }
        }
    }

    // about
    .us-wrap{
        margin-bottom: 80px !important;
        .company-info{
            font-size: 15px !important;
            line-height: 25px !important;
        }
    }

    // contact
    .contact-wrap{
        padding: 30px !important;
        .contact-title{
            margin-bottom: 30px !important;
        }
        .input-wrap{
            gap: 30px !important;
        }
    }
    

}


@media only screen and (max-width: 600px) {

    // about us header align
    .about-us-header{
        // background-position: 62% !important;
    }
    
    // user cards
    .about-us-wrap{
        flex-direction: column;
        max-width: 400px !important;
        margin: auto !important;
        img{
            width: 100% !important;
        }
        .about-user-info{
            padding: 20px !important;
            .about-user-icon{
                i{
                    margin-right: 10px !important;
                }
                a{
                    word-break: break-word;
                }
            }
        }
    }

    // deceased
    .deceased-wrap{
        grid-template-columns: 1fr !important;
    }

    // footer
    .footer-terms{
        line-height: 20px !important;
        font-size: 13px !important;
    }

    .contact-wrap{
        .input-wrap{
            // margin-bottom:  20px;
            grid-template-columns: 1fr !important;
        }
    }
    

}